import { NavbarDropdownType } from '@/src/types'
import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import styles from './index.module.scss'

interface Props {
  collapse: boolean
  setCollapse: Dispatch<SetStateAction<boolean>>
  setDropdownType: Dispatch<SetStateAction<NavbarDropdownType | null>>
}

export default function Hamburger({ collapse, setCollapse, setDropdownType }: Props) {
  const handleToggle = () => {
    setCollapse((pre: boolean) => !pre)
    setDropdownType(null)
  }

  return (
    <button
      type="button"
      className={classNames(styles.hamburger, { [styles['hamburger-close']]: !collapse })}
      onClick={handleToggle}
      data-dropdown-toggle
    >
      <span className={styles['hamburger-bar']}></span>
    </button>
  )
}
