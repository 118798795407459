import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import { Else, If, Then, When } from 'react-if'
import { logout } from 'src/api/msg'
import { SHOPIFY_APP_LINK } from 'src/constants/customers'
import useI18n from 'src/hooks/use_i18n'
import useUserInfo from 'src/hooks/use_user_info'
import useUserMsg from 'src/hooks/use_user_msg'
import { IKV, NavbarDropdownType } from 'src/types'
import { dataSort } from 'src/utils/nav'
import { removeUid } from 'src/utils/tools'
import DropdownHelp from './dropdown_help'
import DropdownLang from './dropdown_lang'
import DropdownProducts from './dropdown_products'
import DropdownTool from './dropdown_tool'
import DropdownUserInfo from './dropdown_user_info'
import DropdownUserMsg from './dropdown_user_msg'
import Hamburger from './hamburger'
import styles from './index.module.scss'
import Ink from './ink'

function Navbar() {
  const { $t, activeLocale: locale } = useI18n()
  const { userInfo, getUserInfo } = useUserInfo()
  const { userMsg, getUserUnReadMsg, batchUserAllMsgRead } = useUserMsg({ getUserInfo })
  const [dropdownType, setDropdownType] = useState<NavbarDropdownType | null>(null)
  const [collapse, setCollapse] = useState<boolean>(true)

  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    if (userInfo.isLogin) {
      getUserUnReadMsg()
    }
  }, [userInfo.isLogin])

  const langInfo = useMemo(() => {
    const langs = dataSort($t('ResGLanguage.items'), '_order') || []
    const current = langs.find((ele) => {
      return ele._code.toLowerCase() === locale
    })

    return {
      list: langs,
      current
    }
  }, [locale])

  const handleDropdown = (type: NavbarDropdownType, e?: IKV) => {
    e?.preventDefault()
    if (type === NavbarDropdownType.MESSAGE && dropdownType !== type) {
      getUserUnReadMsg()
    }
    setDropdownType(dropdownType === type ? null : type)
  }

  useEffect(() => {
    const handler = function (event) {
      const targetElement = event.target

      const isDropdownToggle = (targetElement as any)?.closest('[data-dropdown-toggle]') // 目标元素及其父元素是否包含 data-dropdown-toggle 属性

      if (!isDropdownToggle) {
        setDropdownType(null)
      }
    }
    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [])

  const handleLogout = async () => {
    const res = await logout()
    if (res.Code === 0) {
      removeUid()
      window.location.reload()
    }
  }

  return (
    <nav className={classNames(styles.navbar, { [styles.in]: !collapse })}>
      <header className={styles.header}>
        <Link
          className={classNames('flex items-center cursor-pointer relative', styles['logo-area'])}
          title="17TRACK"
          href={`/${locale}`}
          prefetch={false}
        >
          <Image
            src="https://res.17track.net/global-v2/imgs/logo/svg/full_owt_296x48.svg"
            className={classNames('hidden-xs', styles.logo)}
            alt="17TRACK"
            width={148}
            height={32}
          />
          <Image
            src="https://res.17track.net/global-v2/imgs/logo/svg/square_bot_128x90.svg"
            className={classNames('hide visible-xs', styles.logo)}
            alt="17TRACK"
            width={46}
            height={32}
          />
        </Link>
        <div className="flex items-center">
          <ul
            className={classNames('flex items-center flex-wrap', styles['navbar-toolbar'], styles['navbar-toolbar-pc'])}
          >
            <li className="hidden-md">
              <Link
                className="cursor-pointer p-0"
                prefetch={false}
                href={SHOPIFY_APP_LINK}
                onClick={(e) => {
                  e.preventDefault()
                  window.open(SHOPIFY_APP_LINK)
                }}
              >
                <Image
                  src="https://res.17track.net/asset/imgs/shopify/ShopifyApp.gif"
                  alt="17TRACK"
                  width={150}
                  height={66}
                />
                <Ink />
              </Link>
            </li>
            <li
              className={classNames('hidden-md', { [styles.open]: dropdownType === NavbarDropdownType.PRODUCT })}
              data-dropdown-toggle
            >
              <a
                href="#"
                className="cursor-pointer relative"
                title={$t('ResGlobalPHeaderMenus.global.__product')}
                onClick={(e) => handleDropdown(NavbarDropdownType.PRODUCT, e)}
              >
                <span className="line-clamp-1">{$t('ResGlobalPHeaderMenus.global.__product')}</span>
                <span className={styles.caret}></span>
                <Ink />
              </a>
              {/* 产品下拉列表 */}
              <DropdownProducts dropdownType={dropdownType} />
            </li>
            <li className="hidden-md">
              <a
                className="relative"
                href={`//www.17track.net/${locale}/api`}
                target="_blank"
                title={$t('ResGPHeaderMenus.global.__api')}
              >
                <span className="line-clamp-1">{$t('ResGPHeaderMenus.global.__api')}</span>
                <Ink />
              </a>
            </li>
            <li className="hidden-md">
              <a
                href={`//features.17track.net/${locale}/carriersettlein`}
                target="_self"
                title={$t('ResGlobalPHeaderMenus.global.__carrierIntegration')}
                className="relative"
              >
                <span className="line-clamp-1">{$t('ResGlobalPHeaderMenus.global.__carrierIntegration')}</span>
                <Ink />
              </a>
            </li>
            <li
              className={classNames('hidden-md', { [styles.open]: dropdownType === NavbarDropdownType.TOOL })}
              data-dropdown-toggle
            >
              <a
                href="#"
                className="cursor-pointer relative"
                title={$t('ResGlobalPHeaderMenus.global.__tools')}
                onClick={(e) => handleDropdown(NavbarDropdownType.TOOL, e)}
              >
                <span className="line-clamp-1">{$t('ResGlobalPHeaderMenus.global.__tools')}</span>
                <span className={styles.caret}></span>
                <Ink />
              </a>
              {/* 工具 */}
              <DropdownTool dropdownType={dropdownType} />
            </li>
            <li
              className={classNames('hidden-md', { [styles.open]: dropdownType === NavbarDropdownType.HELP })}
              data-dropdown-toggle
            >
              <a
                href="#"
                className="cursor-pointer relative"
                onClick={(e) => handleDropdown(NavbarDropdownType.HELP, e)}
                title={$t('ResGlobalPHeaderMenus.global.__help')}
              >
                <span className="line-clamp-1">{$t('ResGlobalPHeaderMenus.global.__help')}</span>
                <Ink />
              </a>
              {/* 帮助 */}
              <DropdownHelp dropdownType={dropdownType} />
            </li>
            <li
              className={classNames({ [styles.open]: dropdownType === NavbarDropdownType.LANG })}
              data-dropdown-toggle
            >
              <a
                href="#"
                className="cursor-pointer relative"
                onClick={(e) => handleDropdown(NavbarDropdownType.LANG, e)}
                title={langInfo.current?._name}
              >
                <span className="fa-globe"></span>
                <span className="line-clamp-1">{langInfo.current?._name}</span>
                <span className={styles.caret}></span>
                <Ink />
              </a>
              {/* 语言 */}
              <DropdownLang dropdownType={dropdownType} list={langInfo?.list} />
            </li>
            <If condition={userInfo.isLogin}>
              <Then>
                <li
                  className={classNames({ [styles.open]: dropdownType === NavbarDropdownType.MESSAGE })}
                  data-dropdown-toggle
                >
                  <button
                    title={$t('ResMsg.global.__title')}
                    className="cursor-pointer relative"
                    onClick={(e) => {
                      window.ga?.sendEvent?.('功能操作', '消息组件-图标点击', '', 1)
                      handleDropdown(NavbarDropdownType.MESSAGE, e)
                    }}
                  >
                    <span className="fa-notifications">
                      <When condition={userInfo.hasNewMsg}>
                        <i></i>
                      </When>
                    </span>
                    <Ink />
                  </button>
                  {/* 通知消息 */}
                  <DropdownUserMsg
                    dropdownType={dropdownType}
                    userMsg={userMsg}
                    batchUserAllMsgRead={batchUserAllMsgRead}
                    getUserUnReadMsg={getUserUnReadMsg}
                  />
                </li>
                <li
                  className={classNames({ [styles.open]: dropdownType === NavbarDropdownType.USER_INFO })}
                  data-dropdown-toggle
                >
                  <button
                    className="cursor-pointer relative"
                    onClick={(e) => handleDropdown(NavbarDropdownType.USER_INFO, e)}
                  >
                    <span
                      className={classNames(`spr-avatar-${userInfo.FPhoto}`, styles.avatar, styles['avatar-online'])}
                    >
                      <i></i>
                    </span>
                    <Ink />
                  </button>
                  {/* 用户信息 */}
                  <DropdownUserInfo
                    dropdownType={dropdownType}
                    lang={locale}
                    userInfo={userInfo}
                    handleLogout={handleLogout}
                  />
                </li>
              </Then>
              <Else>
                <li className="hidden-md">
                  <div>
                    <a className={classNames(styles.btn, styles['btn-login'])} href="//user.17track.net">
                      {$t('ResGlobalPHeaderMenus.global.__loginBtn')}
                    </a>
                  </div>
                </li>
                <li className="hidden-md">
                  <div>
                    <a className={classNames(styles.btn, styles['btn-register'])} href="//user.17track.net/register">
                      {$t('ResGlobalPHeaderMenus.global.__registerBtn')}
                    </a>
                  </div>
                </li>
              </Else>
            </If>
          </ul>
          <Hamburger collapse={collapse} setCollapse={setCollapse} setDropdownType={setDropdownType} />
        </div>

        <div className={classNames(styles.mobile, { [styles.in]: !collapse })} data-dropdown-toggle>
          <div className={styles['mobile-navbar-main']}>
            <ul className={styles['navbar-toolbar']}>
              <li>
                <Link
                  className="cursor-pointer p-0"
                  prefetch={false}
                  href={SHOPIFY_APP_LINK}
                  onClick={(e) => {
                    e.preventDefault()
                    window.open(SHOPIFY_APP_LINK)
                  }}
                >
                  <Image
                    src="https://res.17track.net/asset/imgs/shopify/ShopifyApp.gif"
                    alt="17TRACK"
                    width={150}
                    height={66}
                  />
                </Link>
              </li>
              <li className={classNames({ [styles.open]: dropdownType === NavbarDropdownType.PRODUCT })}>
                <a
                  href="#"
                  className="cursor-pointer"
                  onClick={(e) => handleDropdown(NavbarDropdownType.PRODUCT, e)}
                  title={$t('ResGlobalPHeaderMenus.global.__product')}
                >
                  <span>{$t('ResGlobalPHeaderMenus.global.__product')}</span>
                  <span className={styles.caret}></span>
                </a>
                {/* 移动端 - 产品下拉列表 */}
                <DropdownProducts dropdownType={dropdownType} mobile />
              </li>
              <li>
                <a href={`//www.17track.net/${locale}/api`} target="_blank" title={$t('ResGPHeaderMenus.global.__api')}>
                  <span>{$t('ResGPHeaderMenus.global.__api')}</span>
                </a>
              </li>
              <li>
                <a
                  href={`//features.17track.net/${locale}/carriersettlein`}
                  target="_self"
                  title={$t('ResGlobalPHeaderMenus.global.__carrierIntegration')}
                >
                  <span>{$t('ResGlobalPHeaderMenus.global.__carrierIntegration')}</span>
                </a>
              </li>
              <li className={classNames({ [styles.open]: dropdownType === NavbarDropdownType.TOOL })}>
                <a
                  href="#"
                  className="cursor-pointer"
                  title={$t('ResGlobalPHeaderMenus.global.__tools')}
                  onClick={(e) => handleDropdown(NavbarDropdownType.TOOL, e)}
                >
                  {$t('ResGlobalPHeaderMenus.global.__tools')} <span className={styles.caret}></span>
                </a>
                {/* 工具 */}
                <DropdownTool dropdownType={dropdownType} mobile />
              </li>
              <li className={classNames({ [styles.open]: dropdownType === NavbarDropdownType.HELP })}>
                <a
                  href="#"
                  className="cursor-pointer"
                  title={$t('ResGlobalPHeaderMenus.global.__help')}
                  onClick={(e) => handleDropdown(NavbarDropdownType.HELP, e)}
                >
                  {$t('ResGlobalPHeaderMenus.global.__help')}
                </a>
                {/* 帮助 */}
                <DropdownHelp dropdownType={dropdownType} mobile />
              </li>
            </ul>
          </div>
          <When condition={!userInfo.isLogin}>
            <div className={styles['mobile-navbar-footer']}>
              <a className={classNames('cursor-pointer', styles.btn, styles['btn-login'])} href="//user.17track.net">
                {$t('ResGlobalPHeaderMenus.global.__loginBtn')}
              </a>
              <a
                className={classNames('cursor-pointer', styles.btn, styles['btn-register'])}
                href="//user.17track.net/register"
              >
                {$t('ResGlobalPHeaderMenus.global.__registerBtn')}
              </a>
            </div>
          </When>
        </div>
      </header>
    </nav>
  )
}

export default Navbar
