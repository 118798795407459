import classNames from 'classnames'
import dayjs from 'dayjs'
import { memo, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Else, If, Then, When } from 'react-if'
import useI18n from 'src/hooks/use_i18n'
import { MsgState } from 'src/store/msg'
import { NavbarDropdownType } from 'src/types'
import styles from './index.module.scss'

interface Props {
  dropdownType: NavbarDropdownType | null
  userMsg: MsgState
  batchUserAllMsgRead: () => void
  getUserUnReadMsg: () => void
}

function DropdownUserMsg({ dropdownType, userMsg, batchUserAllMsgRead, getUserUnReadMsg }: Props) {
  const { $t, i18nFormat } = useI18n()

  const toViewMsg = (id: string) => {
    window.ga?.sendEvent?.('事件操作', '导航-功能', '17站内信', '点击标题')
    window.location.href = `//msg.17track.net/detail/#id=${id}`
  }

  const toViewAll = () => {
    window.ga?.sendEvent?.('事件操作', '导航-功能', '17站内信', '查看全部')
    window.location.href = '//msg.17track.net'
  }

  // 预请求网络加载失败图片
  const preLoadErrorImage = () => {
    const image = new Image()
    image.src = 'https://res.17track.net/global-v2/imgs/illustration/160x160/network-error.png'
  }

  useEffect(() => {
    preLoadErrorImage()
  }, [])

  return (
    <ul
      className={classNames(styles['custom-dropdown-menu'], styles['dropdown-message'], {
        'animation-scale-up': dropdownType === NavbarDropdownType.MESSAGE,
        [styles.open]: dropdownType === NavbarDropdownType.MESSAGE
      })}
    >
      <li className={styles['dropdown-menu-header']}>
        <span>{$t('ResMsg.global.__title')}</span>
        <button onClick={batchUserAllMsgRead}>{$t('ResMsg.global.__gButton_markAllAsUnRead')}</button>
      </li>
      <li
        className={classNames('custom-scroll', styles['msg-list'], { [styles.loading]: userMsg.status === 'loading' })}
      >
        <If condition={userMsg.status === 'loading'}>
          <Then>
            <Spinner animation="border" variant="primary"></Spinner>
          </Then>
          <Else>
            <If condition={userMsg.msgList.length}>
              <Then>
                <div>
                  {userMsg.msgList.map((ele) => (
                    <div
                      className={classNames('flex cursor-pointer', styles['msg-item'])}
                      key={ele.id}
                      onClick={() => toViewMsg(ele.id)}
                    >
                      <div className={classNames('mr-2.5', styles.circle)}>
                        <i className={classNames('fa-envelope', { [styles['unread-msg']]: ele.state === 0 })}></i>
                      </div>
                      <div className="flex-1">
                        <h6>{ele.title}</h6>
                        <span className={styles.time}>{dayjs(Number(ele.createTime)).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                    </div>
                  ))}
                  <When condition={userMsg.UnReadJCount > 10}>
                    <div className={styles.reminder}>
                      {i18nFormat($t('ResMsg.global.__gContent_unreadMessageOverange'), [
                        <span>&nbsp;{userMsg.UnReadJCount - 10}&nbsp;</span>
                      ])}
                    </div>
                  </When>
                </div>
              </Then>
              <Else>
                <If condition={userMsg.status === 'failed'}>
                  <Then>
                    {/* <!-- 网络错误 --> */}
                    <div className={styles['network-error']}>
                      <p className={styles['error-txt']}>{$t('ResGErrorOther.global.__errorMsg_networkOther')}</p>
                      <span className={styles['error-image']}></span>
                      {/* 刷新 */}
                      <button
                        className={styles['refresh-btn']}
                        onClick={(e) => {
                          e.stopPropagation()
                          getUserUnReadMsg()
                        }}
                      >
                        {$t('ResGWord.global.__other_refresh')}
                      </button>
                    </div>
                  </Then>
                  <Else>
                    {/* <!-- 没有未读信息 --> */}
                    <div className={styles['empty-msg']}>
                      <p className={styles['empty-txt']}>{$t('ResMsg.global.__gContent_noUnreadMessage')}</p>
                      <span className={styles['empty-image']}></span>
                    </div>
                  </Else>
                </If>
              </Else>
            </If>
          </Else>
        </If>
      </li>
      <li className={styles['dropdown-menu-footer']}>
        <a className="cursor-pointer" onClick={toViewAll}>
          {$t('ResGWord.global.__other_viewAll')}
        </a>
      </li>
    </ul>
  )
}

export default memo(DropdownUserMsg)
