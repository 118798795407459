import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { memo, useContext } from 'react'
import { Col } from 'react-bootstrap'
import useI18n from 'src/hooks/use_i18n'
import { IKV, NavbarDropdownType } from 'src/types'
import { NavbarContext } from '../index'
import NavItem from '../navItem'
import styles from './index.module.scss'

/**
 *@Description: right右布局配置项
 */

function NavLeft() {
  const { handleLazyMouseLeave, lazyActiveHandleDropdownFn, responsiveSize } = useContext(NavbarContext)
  const { activeLocale: locale } = useI18n()

  // 用于配置菜单内容
  const defaultNavConfig: IKV = [
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.LOGO_17TRACK,
        content: (
          <Link
            className={classNames('flex items-center cursor-pointer relative', styles['logo-area'])}
            title="17TRACK"
            href={`/${locale}`}
            prefetch={false}
          >
            <Image
              src="https://res.17track.net/global-v2/imgs/logo/svg/full_owt_296x48.svg"
              className={classNames('hidden-lg', styles.logo)}
              alt="17TRACK"
              width={148}
              height={32}
            />
            <Image
              src="https://res.17track.net/global-v2/imgs/logo/svg/square_bot_128x90.svg"
              className={classNames('hide visible-lg', styles.logo)}
              alt="17TRACK"
              width={34}
              height={24}
            />
          </Link>
        ),
        className: styles['img-fluid']
      },
      key: NavbarDropdownType.LOGO_17TRACK,
      href: '#logo',
      props: {
        onMouseEnter: (e) => {
          if (!responsiveSize?.['isLgSize']) return
          lazyActiveHandleDropdownFn?.(NavbarDropdownType.LOGO_17TRACK, e)
        },
        onMouseLeave: () => {
          if (!responsiveSize?.['isLgSize']) return
          handleLazyMouseLeave?.({ timerClean: true, dropdownTimerClean: true })
        }
      }
    }
  ]

  return (
    <>
      <Col className={styles['navbar-left']} xs="auto" md="auto">
        {defaultNavConfig?.map((item, index) => <NavItem key={`left-${index}`} item={item} />)}
      </Col>
    </>
  )
}

export default memo(NavLeft)
