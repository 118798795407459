import classNames from 'classnames'
import { Fragment, memo, useMemo } from 'react'
import { Else, If, Then } from 'react-if'
import useI18n from 'src/hooks/use_i18n'
import { NavbarDropdownType } from 'src/types'
import { dataSort } from 'src/utils/nav'
import styles from './index.module.scss'

interface Props {
  dropdownType: NavbarDropdownType | null
  mobile?: boolean
}

function DropdownProducts({ dropdownType, mobile }: Props) {
  const { $t, activeLocale: locale, i18nFormat } = useI18n()

  const productList = useMemo(() => {
    const menuGroup = dataSort($t('ResGENavigationMenuGroup.items'), '_order')?.map((ele) => ({
      ...ele,
      menus: []
    }))
    const menus = dataSort($t('ResGENavigationMenu.items'), '_order')
    const menusConfig = {
      www: { _isBlank: '0' },
      api: { _isBlank: '1' },
      app: { _isBlank: '0' }
    }
    menus.forEach((item) => {
      Object.assign(item, menusConfig[item._code])

      switch (item._parent) {
        case menuGroup[0].key:
          menuGroup[0].menus.push(item)
          break
        case menuGroup[1].key:
          menuGroup[1].menus.push(item)
          break
        default:
          break
      }
    })

    return menuGroup || []
  }, [])

  const handleNav = (item) => {
    window.ga?.sendEvent?.('事件操作', '导航-功能', `九宫格-${item._code}`)
  }

  return (
    <If condition={mobile}>
      <Then>
        <div
          className={classNames(
            styles['custom-dropdown-menu'],
            styles['dropdown-products'],
            styles['custom-dropdown-menu-mobile'],
            {
              'animation-scale-up': dropdownType === NavbarDropdownType.PRODUCT,
              [styles.open]: dropdownType === NavbarDropdownType.PRODUCT
            }
          )}
        >
          {productList?.map((ele) => (
            <div className={styles['list-group-menu']} key={ele.key}>
              <div className={styles['products-stitle']}>{ele._name}</div>
              {ele.menus?.map((item) => (
                <div key={item._code} onClick={() => handleNav(item)}>
                  <a
                    title={item._name}
                    href={i18nFormat(item._url, [locale])}
                    target={item._isBlank === '1' ? '_blank' : '_self'}
                    className={classNames('cursor-pointer', styles['list-group-item'])}
                  >
                    <i className={`spr-apps-24x24-${item._code}`}></i>
                    <span>{item._name}</span>
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Then>
      <Else>
        <div
          className={classNames(
            styles['custom-dropdown-menu'],
            styles['dropdown-products'],
            styles['custom-dropdown-menu-left'],
            {
              'animation-scale-up': dropdownType === NavbarDropdownType.PRODUCT,
              [styles.open]: dropdownType === NavbarDropdownType.PRODUCT
            }
          )}
        >
          {productList?.map((ele) => (
            <Fragment key={ele.key}>
              <div className={styles['products-stitle']}>{ele._name}</div>
              <ul className={styles['list-group-moreapp']}>
                {ele.menus?.map((item) => (
                  <li
                    className={styles['list-group-item']}
                    title={item._name}
                    key={item._code}
                    onClick={() => handleNav(item)}
                  >
                    <a href={i18nFormat(item._url, [locale])} target={item._isBlank === '1' ? '_blank' : '_self'}>
                      <span className={`spr-apps-${item._code}`}></span>
                      <p>{item._name}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </Fragment>
          ))}
        </div>
      </Else>
    </If>
  )
}

export default memo(DropdownProducts)
