import { lowBrowser, setLoginDimension, setUserId } from './helper'

class GA {
  constructor() { }

  /**
   * GA初始化
   */
  init (gtagId, config, globalTag) {
    this.create(gtagId, config, globalTag)
    this.createGlobalTag()
  }

  /**
   * 初始化ga4(gtag.js)
   * @param {String} gtmId Gtag-ga4媒体ID
   * @param {Object} config Gtag-ga4 config 相关配置
   * @param {Boolean}} globalTag  全局收集ga4配置
   */
  create (gtagId, config, globalTag) {
    // 初始化gtag.js
    try {
      if (typeof window !== 'undefined') {
        const $el = document.querySelectorAll('script[gtag="ga"]')

        for (let i = 0; i < $el.length; i++) {
          const element = $el[i]

          for (const val of ['G-DFLC2LRX2J', 'G-ZD7NTY7WYK', 'G-V4LE7Z0DDV']) {
            // 先清除gtag再创建
            if (element?.getAttribute('src')?.includes(val)) {
              element?.remove()
            }
          }
        }
        ; (function (document, url) {
          const script = document.createElement('script')
          script.setAttribute('async', 1)
          script.setAttribute('src', url)
          script.setAttribute('gtag', 'ga')
          let head = document.getElementsByTagName('head')[0]
          head.append(script)
        })(document, 'https://www.googletagmanager.com/gtag/js?id=' + gtagId)
        window.dataLayer = window.dataLayer || []
        window.gtag = function () {
          try {
            dataLayer.push(arguments)
          } catch (e) {
            console.log('GA-Error', e)
          }
        }
        window.gtag('js', new Date())

        // 把传入的gtagId保存到对象下，这样后续发送事件可以利用到
        this.gtagId = gtagId

        // 如果只初始化全局衡量ID，自定义事件不发送统计过去
        if (this.gtagId === 'G-Y0R74VDYCP') {
          this.gtagId = ''
        }

        const pageName = window.YQ?.configs?.filePath || 'UNKNOW' // 自定义维度1-页面
        const language = window.YQ?.configs?.lang || 'UNKNOW' // 自定义维度2-页面词言
        const browserLevel = lowBrowser() // 自定义维度3-正常浏览器[normal]，低级有calc[low-csscalc]，低级无calc[low-no-csscalc],低级[low]
        const appVersion = window.YQ?.configs?.version || 'UNKNOW' // 自定义维度4-版本号
        const _defaultDimension = {
          dimension1: pageName,
          dimension2: language,
          dimension3: browserLevel,
          dimension4: appVersion,
          dimension5: '0' // 默认匿名维度,
        }

        // 判断是否需要添加配置，否则默认取消gtag的pv发送
        if (Object.prototype.toString.call(config) === '[object Object]') {
          // 合并传入配置与基础维度配置
          for (let key in config) {
            _defaultDimension[key] = config[key]
          }
        }

        // 对于多个config衡量id而言，基础配置set配置使所有衡量id都将继承属性
        // 内嵌跨域标识
        window.gtag('set', 'cookie_flags', 'SameSite=None;Secure')

        // 设置登录用user_id，由于ga4的pv采用gtag自动发送，uid方面提前抽离方法设置
        setUserId()

        // 自定义参数配置gtag
        if (gtagId) {
          window.gtag('config', gtagId, _defaultDimension)
        }

        // 全局数据添加
        if (globalTag) {
          window.gtag('config', 'G-Y0R74VDYCP', {
            location_global: window && window.location && window.location.pathname,
            dimension1: pageName,
            dimension2: language,
            dimension3: browserLevel,
            dimension4: appVersion,
            dimension5: '0' // 默认匿名维度,
          })
        }

        // 中国大陆
        if (window.YQ.country && window.YQ.country === 'CN') {
          window.gtag('config', 'G-6YXZP82DHM', {
            location_global: window && window.location && window.location.pathname,
            dimension1: pageName,
            dimension2: language,
            dimension3: browserLevel,
            dimension4: appVersion,
            dimension5: '0' // 默认匿名维度,
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * 添加PC全局配置
   */
  createGlobalTag () {
    try {
      if (typeof window.gtag === 'function') {
        const pageName = window.YQ?.configs?.filePath || 'UNKNOW' // 自定义维度1-页面
        const language = window.YQ?.configs?.lang || 'UNKNOW' // 自定义维度2-页面语言
        const browserLevel = lowBrowser() // 自定义维度3-正常浏览器[normal]，低级有calc[low-csscalc]，低级无calc[low-no-csscalc],低级[low]
        const appVersion = window.YQ?.configs?.version || 'UNKNOW' // 自定义维度4-版本号

        window.gtag('config', 'G-PW7M299Q8K', {
          location_global: window && window.location && window.location.pathname,
          dimension1: pageName,
          dimension2: language,
          dimension3: browserLevel,
          dimension4: appVersion,
          dimension5: '0' // 默认匿名维度,
        })
      }
    } catch (e) { }
  }

  /**
   * [sendGaEvent GA4事件跟踪: 此方法参数模拟原ga，
   * 由于GA4统计不再像以往GA会有 category、action、label概念与统计，此GA4参数需去后台单独设置维度统计
   * @param  {String} eventName   必须：[事件名称,Require]
   * @param  {Object}    value    非必须：[额外添加的事件]
   * @example
   * sendGaEvent('Seel保险-自动拒绝-咨询按钮',{'event_category':'123'});
   * sendGaEvent('YQinput-filter-结果页追踪');
   */
  sendGaEvent (eventName, value) {
    // 修改ga4额外参数添加设置
    value = value || {}

    const _defaultDimension = {
      send_to: this.gtagId
    }

    // 判断是否需要添加配置，否则默认取消gtag的pv发送
    if (Object.prototype.toString.call(value) === '[object Object]' && Object.keys(value).length !== 0) {
      // 合并传入配置与基础维度配置
      for (let key in value) {
        _defaultDimension[key] = value[key]
      }
    }

    try {
      window.gtag('event', eventName, _defaultDimension)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @Description: [sendEvent 事件跟踪] 保留ga3项目使用方法名，
   * @param  {String} category [通常是用户与之互动的对象,Require]
   * @param  {String} action   [互动类型,Require]
   * @param  {String} label    [用于对事件进行分类]
   * @param  {int}    value    [与事件相关的数值]
   * @return {[type]}          [none]
   * @example
   * sendEvent('YQinput','tracking','首页追踪');
   * sendEvent('YQinput','filter','结果页追踪');
   */
  sendEvent (category, action, label, value) {
    // 用于传递ga4的parameter1
    const ga4Options = {}

    if (category) {
      ga4Options.event_category = category
    }

    if (action) {
      ga4Options.event_action = action
    }

    if (label) {
      ga4Options.event_label = label
    }

    if (value) {
      ga4Options.value = value
    }

    setLoginDimension()

    try {
      this.sendGaEvent(category + '-' + action, ga4Options)
    } catch (err) {
      console.log('ga4--send-error>>>', err)
    }
  }

  /**
   * [sendSocialShare 社交互动，这方法只记录**share**，和下面的sendSocialFollow区分开来]
   * @param  {String} network [发生操作的网络，例如wechat,facebook]
   * @return {[type]}         [description]
   * @example
   * sendSocialShare('facebook')
   */
  sendSocialShare (network) {
    const socialNetwork = network
    setLoginDimension()

    try {
      this.sendGaEvent('share', {
        event_category: 'social',
        event_label: socialNetwork,
        event_action: 'share',
        value: location.href
      })
    } catch (err) {
      console.log('ga4-social>>>', err)
    }
  }

  /**
   * [sendSocialFollow 社交互动，这方法只记录**follow** 和下面的sendSocialShare区分开来]
   * @param  {String} network [发生操作的网络，例如wechat,facebook]
   * @return {[type]}         [description]
   * @example
   * sendSocialFollow('facebook')
   */
  sendSocialFollow (network) {
    const socialNetwork = network

    setLoginDimension()

    try {
      this.sendGaEvent('follow', {
        event_category: 'social',
        event_label: socialNetwork,
        event_action: 'follow',
        value: location.href
      })
    } catch (err) {
      console.log('ga4-social>>>', err)
    }
  }
}

export default new GA()
