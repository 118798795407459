import { supportLanguage } from '@/config/i18nConfig'
import Head from 'next/head'
import { formatCarrierName } from 'src/utils/carriers'

interface LinkSEOProps {
  TagType?: string
  routeLang: string
  currentBrandDetail?: BrandDetail
  customersInfoName?: string
  TagTypeValue?: any // 用于接收某些特殊type需要传递的特殊参数值
}
/**
 *@Description: 是根据数据结果拿到的值
 *@type 
 {
  "key": "190008",
  "_expressGroup": "12",
  "_isOur": "true",
  "_url": "https://www.yunexpress.cn/",
  "_name": "YunExpress",
  "_name_zh-cn": "云途物流",
  "_name_zh-hk": "雲途物流",
  "_type": "default",
  "_detail": "",
  "_detail_zh-cn": "",
  "_detail_zh-hk": "",
  "name": "云途物流",
  "detail": "",
  "fullName": ""
  }
*/
interface BrandDetail {
  _name: { [key: string]: string } // Object with language-specific name properties
}

/**
 *@Description: 根据传递不同的tagType实现不同的link标签渲染
 *@param {String} TagType 你指定的特殊匹配type
 *@param {String} TagTypeValue 特殊匹配的type是否需要传递的特殊值
 *@param {String} routeLang 路由语言
 *@param {String} currentBrandDetail 二级合作商的信息
 *@param {String} customersInfoName shopify客户案例名字
 */
function SeoLinks({ TagType, routeLang, currentBrandDetail, customersInfoName, TagTypeValue }: LinkSEOProps) {
  const wwwUrl = 'https://www.17track.net/'
  let canonicalUrl = '' // 用于提供canonicalUrl
  let xDefaultHrefUrl = '' // 用于提供xDefaultHrefUrl
  const langs = supportLanguage.slice(1) // 配置语言

  switch (TagType) {
    case 'onPage':
      canonicalUrl = wwwUrl + routeLang
      xDefaultHrefUrl = wwwUrl + 'en'
      break
    case 'track_page':
      canonicalUrl = wwwUrl + routeLang + `/track_page`
      xDefaultHrefUrl = wwwUrl + 'en' + `/track_page`
      break
    case 'customers':
      canonicalUrl = wwwUrl + routeLang + `/customers/shopify`
      xDefaultHrefUrl = wwwUrl + 'en' + `/customers/shopify`
      break
    case 'customers_info':
      if (customersInfoName) {
        canonicalUrl = wwwUrl + routeLang + `/customers/shopify/` + customersInfoName
        xDefaultHrefUrl = wwwUrl + 'en' + `/customers/shopify/` + customersInfoName
      }
      break
    case 'brand':
      if (currentBrandDetail) {
        const brandName = decodeURIComponent(formatCarrierName(currentBrandDetail._name))
        canonicalUrl = wwwUrl + routeLang + `/brand/` + brandName
        xDefaultHrefUrl = wwwUrl + 'en' + `/brand/` + brandName
      }
      break
    case 'carriers_index':
      canonicalUrl = wwwUrl + routeLang + `/carriers`
      xDefaultHrefUrl = wwwUrl + 'en' + `/carriers`
      break
    case 'carriers_page':
      if (TagTypeValue) {
        canonicalUrl = wwwUrl + routeLang + `/carriers/page-` + TagTypeValue
        xDefaultHrefUrl = wwwUrl + 'en' + `/carriers/page-` + TagTypeValue
      }
      break
    case 'carriers_info':
      if (TagTypeValue) {
        canonicalUrl = wwwUrl + routeLang + `/carriers/` + TagTypeValue
        xDefaultHrefUrl = wwwUrl + 'en' + `/carriers/` + TagTypeValue
      }
      break
    case 'tracking':
      canonicalUrl = wwwUrl + routeLang + `/tracking`
      xDefaultHrefUrl = wwwUrl + 'en' + `/tracking`
      break
    case 'brands':
      canonicalUrl = wwwUrl + routeLang + `/brands`
      xDefaultHrefUrl = wwwUrl + 'en' + `/brands`
      break
    case 'brands_index':
      canonicalUrl = wwwUrl + routeLang + `/brands`
      xDefaultHrefUrl = wwwUrl + 'en' + `/brands`
      break
    case 'brands_page':
      if (TagTypeValue) {
        canonicalUrl = wwwUrl + routeLang + `/brands/page-` + TagTypeValue
        xDefaultHrefUrl = wwwUrl + 'en' + `/brands/page-` + TagTypeValue
      }
      break
    case 'brands_info':
      if (TagTypeValue) {
        canonicalUrl = wwwUrl + routeLang + `/brands/` + TagTypeValue
        xDefaultHrefUrl = wwwUrl + 'en' + `/brands/` + TagTypeValue
      }
      break
    default:
      break
  }

  // 生成支持语言的hrefLang链接
  const linksArr = langs
    ?.map((langCode) => {
      let hrefUrl = '' // 用于提供hrefUrl
      switch (TagType) {
        case 'onPage':
          hrefUrl = wwwUrl + langCode
          break
        case 'track_page':
          hrefUrl = wwwUrl + langCode + `/track_page`
          break
        case 'tracking':
          hrefUrl = wwwUrl + langCode + `/tracking`
          break
        case 'customers':
          hrefUrl = wwwUrl + langCode + `/customers/shopify`
          break
        case 'customers_info':
          if (customersInfoName) {
            hrefUrl = wwwUrl + langCode + `/customers/shopify/` + customersInfoName
          }
          break
        case 'brand':
          if (currentBrandDetail) {
            const brandName = decodeURIComponent(
              formatCarrierName(currentBrandDetail[`_name_${langCode}`] || currentBrandDetail._name)
            )
            hrefUrl = wwwUrl + langCode + `/brand/` + brandName
          }
          break
        case 'carriers_index':
          hrefUrl = wwwUrl + langCode + `/carriers`
          break
        case 'carriers_page':
          if (TagTypeValue) {
            hrefUrl = wwwUrl + langCode + `/carriers/page-` + TagTypeValue
          }
          break
        case 'carriers_info':
          if (TagTypeValue) {
            hrefUrl = wwwUrl + langCode + `/carriers/` + TagTypeValue
          }
          break
        case 'brands_index':
          hrefUrl = wwwUrl + langCode + `/brands`
          break
        case 'brands_page':
          if (TagTypeValue) {
            hrefUrl = wwwUrl + langCode + `/brands/page-` + TagTypeValue
          }
          break
        case 'brands_info':
          if (TagTypeValue) {
            hrefUrl = wwwUrl + langCode + `/brands/` + TagTypeValue
          }
          break
        default:
          break
      }
      const hreflang = langCode === 'zh-cn' ? 'zh-Hans' : langCode === 'zh-hk' ? 'zh-Hant' : langCode
      // const hreflang = langCode === 'zh-cn' ? '' : langCode === 'zh-hk' ? '' : langCode
      if (hreflang && hrefUrl) {
        return <link rel="alternate" hrefLang={hreflang} href={hrefUrl} key={langCode} />
      } else {
        return null
      }
    })
    .filter(Boolean)

  return (
    <Head>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {xDefaultHrefUrl && <link rel="alternate" hrefLang="x-default" href={xDefaultHrefUrl} />}
      {linksArr}
    </Head>
  )
}

export default SeoLinks
