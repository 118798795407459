import classNames from 'classnames'
import { memo, useState } from 'react'
import useI18n from 'src/hooks/use_i18n'
import { NavbarDropdownType } from 'src/types'
import styles from './index.module.scss'

interface Props {
  dropdownType: NavbarDropdownType | null
  mobile?: boolean
}

function DropdownTool({ dropdownType, mobile }: Props) {
  const { $t, activeLocale: locale, i18nFormat } = useI18n()

  const [toolList] = useState($t('ResGEToolsMenus.items') || [])

  return (
    <div
      className={classNames(styles['custom-dropdown-menu'], styles['dropdown-tool'], {
        'animation-scale-up': dropdownType === NavbarDropdownType.TOOL,
        [styles.open]: dropdownType === NavbarDropdownType.TOOL,
        [styles['custom-dropdown-menu-mobile']]: mobile
      })}
    >
      {toolList?.map((ele) => (
        <div className={styles['list-group-menu']} key={ele.key}>
          <a href={i18nFormat(ele._url, [locale])} title={ele._name} target="_self">
            <i className={`spr-apps-24x24-${ele._code}`}></i>
            <span>{ele._name}</span>
          </a>
        </div>
      ))}
    </div>
  )
}

export default memo(DropdownTool)
