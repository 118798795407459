import classNames from 'classnames'
import { useState } from 'react'
import useI18n from 'src/hooks/use_i18n'
import { NavbarDropdownType } from 'src/types'
import styles from './index.module.scss'

interface Props {
  dropdownType: NavbarDropdownType | null
  mobile?: boolean
}

export default function DropdownHelp({ dropdownType, mobile }: Props) {
  const { $t, activeLocale: locale, i18nFormat } = useI18n()

  const [helpList] = useState($t('ResGEHelpMenus.items') || [])

  return (
    <div
      className={classNames(styles['custom-dropdown-menu'], styles['dropdown-help'], {
        'animation-scale-up': dropdownType === NavbarDropdownType.HELP,
        [styles.open]: dropdownType === NavbarDropdownType.HELP,
        [styles['custom-dropdown-menu-mobile']]: mobile
      })}
    >
      {helpList.map((ele) => (
        <div className={styles['list-group-menu']} key={ele.key}>
          <a
            className="cursor-pointer"
            href={i18nFormat(ele._url, [locale])}
            title={ele._name}
            target={ele.key === '1' ? '_blank' : '_self'}
          >
            <span>{ele._name}</span>
          </a>
        </div>
      ))}
    </div>
  )
}
